import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { FlowObjectDefinition, FlowObjectInstance, FlowObjectInstanceState } from '../../../models/flow-object.model';
import { FlowDefinition } from '../../../models/flow.model';
import { InputDataTaskFinish } from '../../../models/input-output-data.model';
import { EDocsService } from '../../../services/edocs.service';
import { Enums } from '../../../shared/enums';
import { Utils } from '../../../shared/utils';

@Component({
    selector: 'flow-object-finish',
    templateUrl: './flow-object-finish.component.html',
    styleUrls: ['./flow-object-finish.component.scss']
})
export class FlowObjectFinishComponent implements OnInit {
    FlowObjectInstanceState: typeof FlowObjectInstanceState = FlowObjectInstanceState;
    Utils: typeof Utils = Utils;

    DEFAULT_LOADING_TEXT: string = 'Carregando...' as const;

    model: FlowObjectInstance;
    flowObjectDefinition: FlowObjectDefinition = null;
    inputData: InputDataTaskFinish;
    forwardingProtocol: string = '(N/D)';
    processProtocol: string = '(N/D)';
    publicMessageHtml: SafeHtml = this.DEFAULT_LOADING_TEXT;

    @Input() inputModel: FlowObjectInstance;
    @Input() inputFlowDefinition: FlowDefinition;

    constructor(
        private sanitizer: DomSanitizer,
        private toastr: ToastrService,
        private cookieService: CookieService,
        private eDocsService: EDocsService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.model = this.inputModel;
        this.flowObjectDefinition = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.id == this.model.flowObjectDefinitionId);
        this.inputData = JSON.parse(this.model.inputData) as InputDataTaskFinish;

        const response_Encaminhamento = await this.eDocsService.getEncaminhamento(this.inputData.eDocsData.forwardingId);

        if (!response_Encaminhamento.isSuccess) {
            this.toastr.error(response_Encaminhamento.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.forwardingProtocol = response_Encaminhamento.data.protocolo;

        if (!Utils.isNullOrEmpty(this.inputData.eDocsData.processId)) {
            const response_Processo = await this.eDocsService.getProcesso(this.inputData.eDocsData.processId);

            if (!response_Processo.isSuccess) {
                this.toastr.error(response_Processo.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.processProtocol = response_Processo.data.protocolo;
        }

        this.publicMessageHtml = this.sanitizer.bypassSecurityTrustHtml(Utils.getPublicMessageHtml(
            this.cookieService,
            this.flowObjectDefinition,
            this.forwardingProtocol,
            this.processProtocol
        ));
    }

    // ======================
    // public methods
    // ======================

    // ======================
    // private methods
    // ======================
}
