import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import {
    FlowObjectDefinition,
    FlowObjectInstance,
    FlowObjectInstanceState
} from '../../../models/flow-object.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { ConfigSchema } from '../../../models/config-schema.model';
import { FlowDefinition, FlowInstance } from '../../../models/flow.model';
import { InputDataTaskOutboundApi, OutputDataTaskOutboundApi } from '../../../models/input-output-data.model';
import { AcessoCidadaoService } from '../../../services/acesso-cidadao.service';
import { AuthService } from '../../../services/auth.service';
import { EDocsService } from '../../../services/edocs.service';
import { FlowInstanceService } from '../../../services/flow-instance.service';
import { FlowObjectInstanceService } from '../../../services/flow-object-instance.service';
import { Enums } from '../../../shared/enums';
import { Utils } from '../../../shared/utils';

@Component({
    selector: 'flow-object-outbound-api',
    templateUrl: './flow-object-outbound-api.component.html',
    styleUrls: ['./flow-object-outbound-api.component.scss']
})
export class FlowObjectOutboundApiComponent implements OnInit {
    FlowObjectInstanceState: typeof FlowObjectInstanceState = FlowObjectInstanceState;
    Utils: typeof Utils = Utils;

    MAX_RETRIES: number = 5 as const;
    RETRY_INTERVAL_SECONDS: number = 5 as const;
    DEFAULT_LOADING_TEXT: string = 'Carregando...' as const;

    model: FlowObjectInstance;
    flowObjectDefinition: FlowObjectDefinition = null;
    inputData: InputDataTaskOutboundApi;
    publicSystemName: string = '';
    message: string = '';
    forwardingProtocol: string = '(N/D)';
    processProtocol: string = '(N/D)';
    interval: any;
    isMessageFromApi: boolean = false;
    isPollingFinished: boolean = false;
    isResolver: boolean = null;
    publicMessageHtml: SafeHtml = this.DEFAULT_LOADING_TEXT;

    @Input() inputModel: FlowObjectInstance;
    @Input() inputFlowInstance: FlowInstance;
    @Input() inputFlowDefinition: FlowDefinition;
    @Output() outputModelChangeEvent = new EventEmitter<FlowObjectInstance>();

    constructor(
        private sanitizer: DomSanitizer,
        private toastr: ToastrService,
        private confirmationService: ConfirmationService,
        private cookieService: CookieService,
        private authService: AuthService,
        private acessoCidadaoService: AcessoCidadaoService,
        private flowObjectInstanceService: FlowObjectInstanceService,
        private flowInstanceService: FlowInstanceService,
        private eDocsService: EDocsService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.model = this.inputModel;

        const configSchema = JSON.parse(this.model.flowObjectDefinition.configSchema) as ConfigSchema;
        this.publicSystemName = configSchema.taskOutboundApi.publicSystemName;
        this.inputData = JSON.parse(this.model.inputData) as InputDataTaskOutboundApi;

        const response_Encaminhamento = await this.eDocsService.getEncaminhamento(this.inputData.eDocsData.forwardingId);

        if (!response_Encaminhamento.isSuccess) {
            this.toastr.error(response_Encaminhamento.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.forwardingProtocol = response_Encaminhamento.data.protocolo;

        if (!Utils.isNullOrEmpty(this.inputData.eDocsData.processId)) {
            const response_Processo = await this.eDocsService.getProcesso(this.inputData.eDocsData.processId);

            if (!response_Processo.isSuccess) {
                this.toastr.error(response_Processo.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.processProtocol = response_Processo.data.protocolo;
        }

        if (this.isResolver == null) {
            if (!this.authService.user.isPublicAgent) {
                this.isResolver = false;
            } else if (configSchema.taskOutboundApi.resolver.unitId != null) {
                const response = await this.acessoCidadaoService.getConjuntoGestor(configSchema.taskOutboundApi.resolver.unitId);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                if (response.data.sub.toLowerCase() == this.authService.user.id.toLowerCase()) {
                    this.isResolver = true;
                } else {
                    this.isResolver = false;
                }
            } else if (configSchema.taskOutboundApi.resolver.groupId != null) {
                const response = await this.acessoCidadaoService.getConjuntoAgentesPublicos(configSchema.taskOutboundApi.resolver.groupId);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                if (response.data.some(x => x.sub.toLowerCase() == this.authService.user.id.toLowerCase())) {
                    this.isResolver = true;
                } else {
                    this.isResolver = false;
                }
            }
        }

        this.fillMessage();

        this.flowObjectDefinition = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.id == this.model.flowObjectDefinitionId);

        if (this.isFinished()) return;

        if (this.model.stateId == FlowObjectInstanceState.Started) {
            this.pollForUpdate();
        } else {
            this.isPollingFinished = true;
        }

        this.publicMessageHtml = this.sanitizer.bypassSecurityTrustHtml(Utils.getPublicMessageHtml(
            this.cookieService,
            this.flowObjectDefinition,
            this.forwardingProtocol,
            this.processProtocol
        ));
    }

    // ======================
    // public methods
    // ======================

    isFinished(): boolean {
        return [
            FlowObjectInstanceState.Finished,
            FlowObjectInstanceState.ManuallyFinished,
            FlowObjectInstanceState.AutomaticallyCancelled,
            FlowObjectInstanceState.ManuallyCancelled
        ].includes(this.model.stateId);
    }

    async enqueueTask() {
        this.confirmationService.confirm({
            message: Enums.Messages.ConfirmEnqueueTask,
            accept: async () => {
                const response = await this.flowObjectInstanceService.enqueueTask(this.model);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                this.model = response.data;
                this.outputModelChangeEvent.emit(this.model);
                this.isPollingFinished = false;
                this.pollForUpdate();
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    async updateTask(stateId: number){
        this.confirmationService.confirm({
            message: stateId == FlowObjectInstanceState.ManuallyFinished ? Enums.Messages.ConfirmFinishTask : Enums.Messages.ConfirmCancelTask,
            accept: async () => {
                let currentStateId = this.model.stateId;
                this.model.stateId = stateId;

                const response = await this.flowInstanceService.manuallyFinishOrCancel(this.model);

                if (!response.isSuccess) {
                    this.model.stateId = currentStateId;
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                this.getFlowObjectInstance(currentStateId);
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    getDateString(): string {
        return new Date(this.model.updateDate).toLocaleString();
    }

    // ======================
    // private methods
    // ======================

    private pollForUpdate() {
        let count = 0;
        this.interval = setInterval(() => {
            if (count == this.MAX_RETRIES + 1 || this.isFinished()) {
                clearInterval(this.interval);
                this.isPollingFinished = true;
                return;
            }

            this.getFlowObjectInstance();
            count++;
        }, this.RETRY_INTERVAL_SECONDS * 1000);
    }

    private async getFlowObjectInstance(stateId?: number) {
        const response = await this.flowObjectInstanceService.getById(this.model.id);

        if (!response.isSuccess) return;

        if (response.data.stateId == FlowObjectInstanceState.AwaitingAction) {
            clearInterval(this.interval);
            this.isPollingFinished = true;
        }

        if (
            this.model.stateId != response.data.stateId
            || (
                stateId != null
                && stateId != response.data.stateId
            )
        ) {
            this.model = response.data;
            this.outputModelChangeEvent.emit(this.model);
        }

        this.fillMessage();
    }

    private fillMessage() {
        if (Utils.isNullOrEmpty(this.model?.outputData)) {
            this.message = '';
        } else {
            const outputData = JSON.parse(this.model.outputData) as OutputDataTaskOutboundApi;

            if (!Utils.isNullOrEmpty(outputData?.response?.mensagem)) {
                this.message = outputData.response?.mensagem;
                this.isMessageFromApi = true;
            } else if (outputData?.response?.sucesso != null && outputData?.response?.sucesso === false) {
                this.message = JSON.parse(this.model.flowObjectDefinition.configSchema).taskOutboundApi.errorMessage;
            }
        }
    }
}
