import { LocationType } from "./config-schema.model";

export class BaseInputOutputData {
    eDocsData?: InputOutputDataEDocs;
}

export class InputOutputDataEDocs {
    documentsIds?: string[];
    forwardingId?: string;
    signerId?: string;
    processId?: string;
    currentForwardingLocationId?: string;
    isCurrentForwardingLocationGroupOrUnit?: boolean;
}

// #region [TaskForm]
export class InputDataTaskForm extends BaseInputOutputData {
    data?: object;
    fileUrls?: string;

    public constructor(init?: object) {
        super();
        this.data = init;
        this.eDocsData = new InputOutputDataEDocs();
    }
}

export class OutputDataTaskForm extends BaseInputOutputData { }
// #endregion

// #region [TaskForward]
export class InputDataTaskForward extends BaseInputOutputData {
    recipientId?: string;
    recipientName?: string;
    recipientType?: LocationType;
}

export class OutputDataTaskForward extends BaseInputOutputData { }
// #endregion

// #region [TaskRegisterProcess]
export class InputDataTaskRegisterProcess extends BaseInputOutputData {
    cancelMessage?: string;

    public constructor() {
        super();
        this.eDocsData = new InputOutputDataEDocs();
    }
}

export class OutputDataTaskRegisterProcess extends BaseInputOutputData {
    public constructor() {
        super();
        this.eDocsData = new InputOutputDataEDocs();
    }
}
// #endregion

// #region [TaskDispatchProcess]
export class InputDataTaskDispatchProcess extends BaseInputOutputData {
    recipientId?: string;
    recipientName?: string;

    public constructor() {
        super();
        this.eDocsData = new InputOutputDataEDocs();
    }
}

export class OutputDataTaskDispatchProcess extends BaseInputOutputData {
    public constructor() {
        super();
        this.eDocsData = new InputOutputDataEDocs();
    }
}
// #endregion

// #region [TaskApprove]
export class InputDataTaskApprove extends BaseInputOutputData {
    hasApproved: boolean = false;
    message: string = null;

    public constructor() {
        super();
        this.eDocsData = new InputOutputDataEDocs();
    }
}

export class OutputDataTaskApprove extends BaseInputOutputData { }
// #endregion

// #region [TaskAcknowledge]
export class InputDataTaskAcknowledge extends BaseInputOutputData {
    message: string = null;

    public constructor() {
        super();
        this.eDocsData = new InputOutputDataEDocs();
    }
}

export class OutputDataTaskAcknowledge extends BaseInputOutputData { }
// #endregion

// #region [TaskOutboundApi]
export class InputDataTaskOutboundApi extends BaseInputOutputData { }

export class OutputDataTaskOutboundApi extends BaseInputOutputData {
    response?: ResponseOutboundApi;

    public constructor() {
        super();
        this.response = new ResponseOutboundApi();
        this.eDocsData = new InputOutputDataEDocs();
    }
}

export class ResponseOutboundApi {
    sucesso?: boolean;
    mensagem?: string;
}
// #endregion

// #region [TaskMidwayOutboundApi]
export class InputDataTaskMidwayOutboundApi extends BaseInputOutputData { }

export class OutputDataTaskMidwayOutboundApi extends BaseInputOutputData {
    response?: any;

    public constructor() {
        super();
        this.response = {};
        this.eDocsData = new InputOutputDataEDocs();
    }
}
// #endregion

// #region [TaskGatewayRules]
export class InputDataTaskGatewayRules extends BaseInputOutputData {
    response?: any;

    public constructor() {
        super();
        this.response = {};
        this.eDocsData = new InputOutputDataEDocs();
    }
}

export class OutputDataTaskGatewayRules extends BaseInputOutputData {
    hasApproved?: boolean;

    public constructor() {
        super();
        this.hasApproved = null;
        this.eDocsData = new InputOutputDataEDocs();
    }
}
// #endregion

// #region [TaskGatewayPaths]
export class InputDataTaskGatewayPaths extends BaseInputOutputData { }

export class OutputDataTaskGatewayPaths extends BaseInputOutputData {
    shouldFollowFirstGatewayPath?: boolean = false;

    public constructor() {
        super();
        this.eDocsData = new InputOutputDataEDocs();
    }
}
// #endregion

// #region [TaskFinish]
export class InputDataTaskFinish extends BaseInputOutputData { }

export class OutputDataTaskFinish extends BaseInputOutputData { }
// #endregion
