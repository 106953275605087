import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ConfigSchema } from "../../../models/config-schema.model";
import { FlowObjectInstance, FlowObjectInstanceState } from "../../../models/flow-object.model";
import { OutputDataTaskMidwayOutboundApi } from "../../../models/input-output-data.model";
import { FlowObjectInstanceService } from "../../../services/flow-object-instance.service";
import { Utils } from "../../../shared/utils";

@Component({
    selector: "flow-object-midway-outbound-api",
    templateUrl: "./flow-object-midway-outbound-api.component.html",
    styleUrls: ["./flow-object-midway-outbound-api.component.scss"]
})

export class FlowObjectMidwayOutboundApiComponent implements OnInit {
    FlowObjectInstanceState: typeof FlowObjectInstanceState = FlowObjectInstanceState;
    Utils = Utils;

    MAX_RETRIES: number = 5 as const;
    RETRY_INTERVAL_SECONDS: number = 5 as const;

    model: FlowObjectInstance;
    publicSystemName: string = '';
    message: string = '';
    interval: any;
    isPollingFinished: boolean = false;

    @Input() inputModel: FlowObjectInstance;
    @Output() outputModelChangeEvent = new EventEmitter<FlowObjectInstance>();

    constructor(private flowObjectInstanceService: FlowObjectInstanceService) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.model = this.inputModel;

        const configSchema = JSON.parse(this.model.flowObjectDefinition.configSchema) as ConfigSchema;
        this.publicSystemName = configSchema.taskOutboundApi.publicSystemName;

        if (
            this.model.stateId == FlowObjectInstanceState.Started
            && (
                configSchema.taskOutboundApi.backgroundExecution != null
                && configSchema.taskOutboundApi.backgroundExecution === true
            )
        ) {
            this.pollForUpdate();
        } else {
            this.isPollingFinished = true;
            this.fillMessage();
        }
    }

    // ======================
    // public methods
    // ======================

    // ======================
    // private methods
    // ======================

    private pollForUpdate() {
        let count = 0;
        this.interval = setInterval(() => {
            if (count == this.MAX_RETRIES + 1) {
                clearInterval(this.interval);
                this.isPollingFinished = true;
                this.fillMessage();
                return;
            }

            this.getFlowObjectInstance();
            count++;
        }, this.RETRY_INTERVAL_SECONDS * 1000);
    }

    private async getFlowObjectInstance() {
        const response = await this.flowObjectInstanceService.getById(this.model.id);

        if (!response.isSuccess) return;

        if (response.data.stateId === FlowObjectInstanceState.Finished) {
            clearInterval(this.interval);
            this.isPollingFinished = true;
            this.model = response.data;
            this.outputModelChangeEvent.emit(this.model);
            this.fillMessage();
        }
    }

    private fillMessage() {
        const configSchema = JSON.parse(this.model.flowObjectDefinition.configSchema) as ConfigSchema;

        if (Utils.isNullOrEmpty(this.model?.outputData)) {
            this.message = configSchema.taskOutboundApi.errorMessage;
        } else {
            const outputData = JSON.parse(this.model?.outputData) as OutputDataTaskMidwayOutboundApi;
            if (!Utils.isNullOrEmpty(outputData.response?.mensagem)) {
                this.message = outputData.response.mensagem;
            } else if (Utils.isString(outputData.response)) {
                this.message = outputData.response;
            }
        }
    }
}
