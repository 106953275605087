<div class="wrapper px-1" [class.single]="model.stateId == FlowObjectInstanceState.Finished || !isPollingFinished" *ngIf="model != null">
    <ng-container *ngIf="model.stateId == FlowObjectInstanceState.Finished; else pollingTemplate">
        <div class="message-container">
            <div class="message-row">
                <i class="fas fa-check-circle success mr-3"></i>
                <span class="headline">
                    Comunicação com o sistema
                    <span class="text-highlight relevant-info-font">{{publicSystemName}}</span>
                    realizada com sucesso
                </span>
                <i class="fas fa-check-circle success ml-3"></i>
            </div>
        </div>
    </ng-container>

    <ng-template #pollingTemplate>
        <div class="message-container" *ngIf="!isPollingFinished; else errorTemplate">
            <div class="message-row mt-2">
                <i class="fas fa-sync fa-spin await mr-3"></i>
                <span class="headline">
                    Aguardando comunicação com o sistema
                    <span class="d-inline-block text-highlight relevant-info-font">{{publicSystemName}}</span>
                </span>
                <i class="fas fa-sync fa-spin await ml-3"></i>
            </div>
        </div>
    </ng-template>

    <ng-template #errorTemplate>
        <div class="message-container">
            <div class="message-row mt-2">
                <i class="fas fa-exclamation-circle error mr-3"></i>
                <span class="headline">
                    Erro na comunicação com o sistema
                    <span class="d-inline-block text-highlight relevant-info-font">{{publicSystemName}}</span>
                </span>
                <i class="fas fa-exclamation-circle error ml-3"></i>
            </div>

            <ng-container *ngIf="!Utils.isNullOrEmpty(message)">
                <div class="message-row" *ngIf="isMessageFromApi">
                    <p>Mensagem retornada pelo sistema:</p>
                </div>

                <div class="message-row" [class.mt-4]="!isMessageFromApi">
                    <span class="document-highlight relevant-info-font">{{message}}</span>
                </div>
            </ng-container>
        </div>
    </ng-template>
</div>
